<template>
  <footer class="foot">
      <div class="container-xxl">
        <div class="col-12 mx-auto ">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-xl-2 mx-md-auto mx-0 py-3  col-12 text-center text-lg-left">
                    <img src="media/logos/brandsolana.png" style="max-width: 220px" class="img-fluid mx-auto mb-2" alt="toyota-white">
                    <div class="d-flex col-lg-6 col-4 mx-auto my-2">
                    </div>
                </div>
                <div class="col-lg-2 col-xl-1 col-md-2 py-3 col-12 px-1">
                    <p class="fw-bold fs-3 p-0 text-break">Menú</p>
                </div>
                <div class="col-lg-2 col-md-4 py-3 col-12">
                    <p><a href="https://solanadev.demosturn.com/#/catalogo" class="fw-light colorW ">Compra un vehículo</a></p>
                    <p><a href="https://solanadev.demosturn.com/#/vender-auto" class="fw-light colorW">Vende tu vehículo</a></p>
                    <p><a href="https://solanadev.demosturn.com/#/home#map" class="fw-light colorW">Sucursales</a></p>
                </div>
                <div class="col-lg-2 col-xl-1 col-md-2 py-3 col-12 px-1">
                    <p class="fw-bold fs-3 p-0 text-break">Sobre</p>
                </div>
                <div class="col-lg-2 col-md-4 py-3 col-12">
                    <p class="fw-bold">Sobre nosotros</p>
                    <p class="fw-light">Servicios</p>
                    <p><a href="docs/TyC Solanaseminuevos.pdf" class="fw-light colorW" target="_blank">Términos y condiciones</a></p>
                    <p class="fw-light">Políticas</p>
                    <p><a href="docs/Aviso de Privacidad Solanaseminuevos.pdf" class="fw-light colorW" target="_blank">Aviso de privacidad</a></p>
                </div>
                <div class="col-lg-2 col-xl-1 col-md-2 py-3 col-12 px-1">
                    <p class="fw-bold fs-3 p-0 text-break">Contacto</p>
                </div>
                <div class="col-lg-2 col-md-4 py-3 col-12 ps-lg-4">
                    <p class="fw-bold d-flex align-items-center pb-2"><img class="fs-2 me-2" style="filter: invert(1)" src="media/icons/sms.svg" alt="correo"/> info@gruposolana.com</p>
                    <p class="fw-light d-flex align-items-center pb-2"><img class="fs-2 me-2" style="filter: invert(1)" src="media/icons/call.svg" alt="phone"/> 333 111 8640</p>
                    <p class="fw-light d-flex align-items-start"><img class="fs-2 me-2" style="filter: invert(1)" src="media/icons/location.svg" alt="location"/> Av. Paseo Royal Country #4596, Puerta de Hierro, C.P. 45116 Zapopan, Jal.</p>
                </div>
            </div>
        </div>
        <hr class="text-light">
        <div class="col-lg-12 mx-auto">
            <div class="row">
                <span class="col-lg-3 mx-auto text-gray text-center" >© 2022 Todos los derechos reservados</span>
            </div>
        </div>
      </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    .foot{
        padding: 3em 2em;
        color: #f7f7f7;
        background-color: black;
        margin-top: auto;
    }
    p{
        margin: 0;
        a{
            color: white
        }
    }

</style>
